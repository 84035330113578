import React, { useState, useEffect } from 'react';
import './PriceTracker.css';

const TIME_PERIODS = [
  { label: '5M', value: 'm5' },
  { label: '1H', value: 'h1' },
  { label: '6H', value: 'h6' },
  { label: '24H', value: 'h24' },
  { label: 'ATL', value: 'atl' }
];

const PriceTracker = ({ tokenData }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('h24');
  const [prices, setPrices] = useState({
    PLS: { price: 0, priceChange: {} },
    PLSX: { price: 0, priceChange: {} },
    INC: { price: 0, priceChange: {} },
    HEX: { price: 0, priceChange: {} }
  });

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:3000'}/api/price-data`);
        const priceData = await response.json();
        setPrices(priceData);
      } catch (error) {
        console.error('Error fetching price data:', error);
      }
    };

    // Fetch immediately and then every 30 seconds
    fetchPriceData();
    const interval = setInterval(fetchPriceData, 30000);

    return () => clearInterval(interval);
  }, []);

  const formatPrice = (price, token) => {
    const numPrice = parseFloat(price);
    
    if (token === 'INC') {
      return numPrice >= 1 ? numPrice.toFixed(2) : numPrice.toFixed(9);
    }
    
    if (numPrice < 0.0001) {
      return numPrice.toFixed(7);
    } else if (numPrice < 0.01) {
      return numPrice.toFixed(5);
    } else if (numPrice < 1) {
      return numPrice.toFixed(4);
    } else {
      return numPrice.toFixed(2);
    }
  };

  const getChangeValue = (token, period) => {
    return prices[token]?.priceChange?.[period] || 0;
  };

  const formatPercentage = (value) => {
    const absValue = Math.abs(value);
    return absValue < 10 ? absValue.toFixed(2) : absValue.toFixed(1);
  };

  return (
    <div className="price-tracker-container">
      <div className="time-period-toggles">
        {TIME_PERIODS.map(({ label, value }) => (
          <button
            key={value}
            className={`time-toggle ${selectedPeriod === value ? 'active' : ''}`}
            onClick={() => setSelectedPeriod(value)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="tracker-container">
        {Object.entries(prices).map(([token, data]) => (
          <div key={token} className="token-card">
            <div className="token-info">
              <img src={tokenData[token].image} alt={token} className="price-token-icon" />
              <span className="token-symbol">{token}</span>
            </div>
            <div className="token-price-container">
              <div className="token-price">
                ${formatPrice(data.price, token)}
              </div>
              <div className={`price-change ${getChangeValue(token, selectedPeriod) >= 0 ? 'positive' : 'negative'}`}>
                <i className={`fas fa-arrow-${getChangeValue(token, selectedPeriod) >= 0 ? 'up' : 'down'}`} />
                {getChangeValue(token, selectedPeriod) >= 0 ? '+' : ''}
                {formatPercentage(getChangeValue(token, selectedPeriod))}%
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PriceTracker;